import {Wrapper, Background, InputContainer, ButtonContainer, Alerta, AlertaT} from './styles';
import { useNavigate, Link } from 'react-router-dom';
import React, {useState, useContext} from 'react';
import background from '../../assets/images/background-login.jpg';
import nowT from '../../assets/images/nowT.bmp';
import Card from '../../components/CardLogin';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../Services/api';
import { AxiosResponse } from 'axios';
import { useLocation} from 'react-router-dom';
import { useEffect } from "react";
import swal from 'sweetalert'

const SignIn = () => {

    const [usuario, setUsuario] = useState('');
    const [senha, setsenha] = useState('');
    const [sucesso, setSucesso] = useState('');  
    const [token, setToken] = useState(''); 
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const something=(event:any)=> {
        if (event.keyCode === 13) {
            handleToSingIn();
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem("userToken") !== null) {
        navigate('/dashboard');
        }
         }, [navigate]);

      

    async function handleToSingIn(){
        let ret:AxiosResponse;
     
        try{
             ret = await api.get('/user/login',{params:{
                username: usuario,
                password: senha
            }})
          
           
            if (ret.data.status === 200){
                setSucesso('S');    
              
                sessionStorage.setItem("message", ret.data.message);
                sessionStorage.setItem("userToken", ret.data.token);
                sessionStorage.setItem("username", ret.data.username);
                sessionStorage.setItem("dados", JSON.stringify(ret.data.data));     
                navigate('/dashboard');
                
            } else {
             
                setSucesso('N');
            }   
        }catch(e: any){
            if(e.response === undefined){
                swal({
                    title: "ATENÇÃO",
                    text:`Não foi possível realizar o login, erro: ${e.message}.` ,
                    icon: "error",
                    button: "OK",
                }); 
                setSucesso('E');
            } else {
                setSucesso('N');
                }
            
        }
        
    }
    
    return (
        <>
        <Wrapper>
            <Background image={background}/>
            <Card width="403px" height="auto">
            <img src={nowT} width={300} height={100} alt="now" />

                <InputContainer>
                    <Input placeholder="Digite aqui seu usuário" onChange={(e) => setUsuario(e.target.value)}/>
                    <Input placeholder="Digite aqui sua senha" type="password" onKeyDown={(e) => something(e) } onChange={(e) => setsenha(e.target.value)}/>
                </InputContainer>
                {sucesso === 'N' ? <AlertaT><p>Usuário ou senha inválida.</p></AlertaT> : null}
                <ButtonContainer>
                    <Button type="button" onClick={handleToSingIn}>ENTRAR</Button>
                  
                    {/* <p><Link to="/signup">Esqueceu sua senha?</Link></p> */}
                </ButtonContainer>
                
            </Card>
            
           
        </Wrapper>
</>
    )
}

export default SignIn;
