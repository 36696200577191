import { Containertab, DashboardBackground, ModalAlinham, BodyContainer, Divbut, InlineContainer2, InlineContainer, Bot, HistoryChamados, DivTitulo, LoginInput, ButtonContainer, Container, Title, Tag } from './styles';
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import HistoryFinanceCard from '../../components/HistoryFinanceCard';
import api from "../../Services/api";
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Input from '../../components/Input';
import { useNavigate } from 'react-router';
import { BsFillFileTextFill } from "react-icons/bs";
import moment from 'moment';
import Modal, { ModalHeader, ModalBody, ModalFooter, useModal } from '../../components/ModalQuant/Modal';
import { ImArrowRight, ImArrowLeft } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { FcHighPriority } from "react-icons/fc";
import { FaBeer } from "react-icons/fa";
import { FaClipboardList } from 'react-icons/fa';
import { FaStar } from "react-icons/fa";
import Select from 'react-select'

interface Locais {
    idLocal: string;
    descricao: string;
    pavimento: string;
    ativo: number;
    ambiente: string;
    grupodelocais: string;
    equiperespons: string;
    ID: number;
}

const Dashboard = (props: any) => {

    const Obj = JSON.parse(sessionStorage.getItem("dados") || '{}');
    const [toggleState, setToggleState] = useState(1);
    const { isShowing, toggle } = useModal();
    const [empresa, setEmpresa] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tombamento, setTombamento] = useState('');
    const [local, setLocal] = useState('');
    const [observacao, setObservacao] = useState('');
    const [tipoSolicitacao, setTipoSolicitacao] = useState('');
    const [subtipo, setSubtipo] = useState('');
    const [email, setEmail] = useState('');
    const location = useLocation();
    const [mensagem, setMensagem] = useState('');
    //  const [ambientes, setAmb] = useState(teste);
    const [task, setTask] = useState([]);
    const navigate = useNavigate();
    const [filtro, setFiltro] = useState(99);
    const [ativarButton, setAtivarButton] = useState(undefined);
    const [dataIni, setDataini] = useState('');
    const [datafim, setDatafim] = useState('');
    const [notif, setNotif] = useState('9');
    const [renderNotif, setRenderNotif] = useState();
    const [quantNot, setQuantNot] = useState('0');
    const [userApp, setUserApp] = useState('');

    const [optionsEmpresa, setOptionsEmpresa] = useState([])
    const [optionsLocais, setOptionsLocais] = useState([])
    const [optionsLocaisFilter, setOptionsLocaisFilter] = useState([])

    const [optionsTipoSolicitacao, setOptionsTipoSolicitacao] = useState([])
    const [optionsSubTipo, setOptionsSubTipo] = useState([])
    const [optionsSubTipoFilter, setOptionsSubTipoFilter] = useState([])

    api.defaults.headers.common['Authorization'] = sessionStorage.getItem("userToken") || '';



    //  function teste() {
    //      const value = JSON.parse(sessionStorage.getItem("dados"));
    //     return value.map((data: any) => {
    //         return <option value={data.OS_ambiente + '/' + data.OS_grupo + '/' + data.OS_token + '/' + data.OS_usu} > {data.OS_descricao} - {data.OS_grupo} </option>
    //    })
    //  }

    const [data, setData] = useState([]);
    const [tasks, setTasks] = useState([])
    const [selection, setSelection] = useState({ 20: "", 30: "", 40: "", 50: "", 99: "" });

    useEffect(() => {
 
        carregarEmpresa()
        
        setResponsavel(sessionStorage.getItem("username") || "")
    }, []);

    function carregarEmpresa() {
        const value = JSON.parse(sessionStorage.getItem("dados") || '');

        const arr = [] as any;

        let result = value;

        result.map((data: any) => {
            return arr.push({ value: data.OS_ambiente 
                + '/' + data.OS_grupo 
                + '/' + data.OS_token 
                + '/' + data.OS_usu, 
                label: data.OS_descricao + " - " + data.OS_grupo });
        })

        setOptionsEmpresa(arr)

    }

    function PegarLocais(id: any) {
        setOptionsSubTipoFilter([])
        setOptionsLocaisFilter([])
        setOptionsTipoSolicitacao([])
        setLocal("")
        setTipoSolicitacao("")
        setSubtipo("")
        setTombamento("")
        if (id !== "") {
 
            setEmpresa(id.split('/')[0] + '/' + id.split('/')[1] + '/' + id.split('/')[2] + '/' + id.split('/')[3]);
            getLoc(id.split('/')[1], id.split('/')[3])
            getTaskIni(id.split('/')[2])


          if(id.split('/')[0] === 'ADLIMTI'){

            getTipos()

          }else if(id.split('/')[0] === 'ADLIMMANUTENCAO'){
            setOptionsTipoSolicitacao([{
                value: "MANUTENCAO" + '/' + "MANUTENÇÃO",
                label: "MANUTENÇÃO"
            }, {
                value: "ARCONDICIONADO" + '/' + "AR CONDICIONADO",
                label: "AR CONDICIONADO"
            }] as any)
          }
        
        } else {
            setEmpresa("")
         
        }

    }

    async function getTipos() {
        let ret: AxiosResponse;
      
        try {
            ret = await api.get('user/tipos')

            if (ret.data.status === 200) {

           
                const arr = [] as any;

                let result = ret.data.tipo;
        
                result.map((data: any) => {
                    return arr.push({ value: String(data.tty_integrationid  + '/' + data.tty_description), label: data.tty_description });
                })
                
     
                setOptionsTipoSolicitacao(arr)
                setOptionsSubTipo(ret.data.subtipo)

            } 
        } catch (e) {
            setOptionsTipoSolicitacao([])
            if (e.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Token inválido ou expirado! Você precisa sair e entrar novamente no portal.`,
                    confirmButtonColor: "#222d32",
                })
                
         

            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Não foi possível concluir sua solicitação erro: ${e.response.status}`,
                    confirmButtonColor: "#222d32",
                })
             
            }
            
        }

    }

    async function getLoc(grupo: any, user: any) {
        let ret: AxiosResponse;
        let valor: any;
        setLocal('');
        setDatafim('');
        setDataini('');
    
        try {
            ret = await api.get('user/umovLocais', {
                params: {
                    grupo: grupo,
                    user: user
                }
            })

            if (ret.data.status === 200) {

              
                setOptionsLocais(ret.data.data)
  


            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Não foi possível concluir sua solicitação.`,
                    confirmButtonColor: "#222d32",
                })
            }
        } catch (e) {
            if (e.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Token inválido ou expirado! Você precisa sair e entrar novamente no portal.`,
                    confirmButtonColor: "#222d32",
                })
             


            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Não foi possível concluir sua solicitação erro: ${e.response.status}`,
                    confirmButtonColor: "#222d32",
                })
              
            }
            
        }

        //buscar historico de chamados

        setData(valor);
        return valor;
    }

    async function salvarTar() {

        
   const value = JSON.parse(sessionStorage.getItem("dados") || '');

        const s = document.getElementById('salvar');
        let ret: AxiosResponse;

        if (empresa === '') {


            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Selecione a empresa desejada.",
                confirmButtonColor: "#222d32",
            })

        }
        else if (responsavel === '') {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Informe o Responsável.",
                confirmButtonColor: "#222d32",
            })


        }
        else if (telefone === '') {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Informe o Telefone.",
                confirmButtonColor: "#222d32",
            })


        }
        else if (tipoSolicitacao === '') {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Informe o tipo de solicitação.",
                confirmButtonColor: "#222d32",
            })


        }
        else if (local === '') {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Informe o Local.",
                confirmButtonColor: "#222d32",
            })


        }
        else if (observacao === '') {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Informe o problema encontrado.",
                confirmButtonColor: "#222d32",
            })


        }
        else {

            let tipoSolic: any
            let equipe: any


             if(empresa.split('/')[0] === 'ADLIMTI'){

                tipoSolic = tipoSolicitacao.split('/')[0]
                equipe = 'ADLIMTI'

              } else if(empresa.split('/')[0] === 'ADLIMMANUTENCAO'){

                tipoSolic = 'CR'
                equipe = ''
                
              }


            try {
                ret = await api.get('user/send', {
                    params: {
                        token: empresa.split('/')[2],
                        ambiente: empresa.split('/')[0],
                        grupo: empresa.split('/')[1],
                        requisitante: sessionStorage.getItem("username"),
                        responsavel: responsavel,
                        idLocal: local.split('/')[0],
                        fone: telefone,
                        obs: observacao,
                        tipoSolicitacao: tipoSolic,
                        usuario: equipe,
                        email: local.split('/')[1]+";"+value[0].emailusuario,
                        descLocal: local.split('/')[2],
                        descSolicitacao: tipoSolicitacao.split('/')[1],
                        subTipo: subtipo.split('/')[0],
                        tombamento: tombamento
                    }
                })

                if (ret.data.status === 201) {

                    setResponsavel('');
                    setTelefone('');
                    setObservacao('');
                    setLocal('')
                    setTipoSolicitacao('')
                    setSubtipo("")
                    setTombamento("")
                    getTask();

                    Swal.fire({
                        icon: 'success',
                        text: 'Chamado aberto com sucesso.',
                        confirmButtonColor: "#222d32",
                    })




                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: `Não foi possível concluir o chamado erro: ${ret.data.status}`,
                        confirmButtonColor: "#222d32",
                    })


                }

            } catch (e: any) {
                if (e.response.status === 401) {

                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: `Token inválido ou expirado! Você precisa sair e entrar novamente no portal.`,
                        confirmButtonColor: "#222d32",
                    })


                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: `Não foi possível concluir sua solicitação erro: ${e.response.status}`,
                        confirmButtonColor: "#222d32",
                    })


                }


            }
        }
        s.disabled = false
    }


    function reactObj(objeto: any) {
        const chamados = objeto.map((prod: any, index: any) => {
            return <HistoryFinanceCard
                key={index}
                tagColor="#4CAF50"
                obj={prod}
                updateTask={updateTask}
                empresa={sessionStorage.getItem("empresa") || ''}
            />
        })
        return chamados;

    }

    async function getTaskIni(token: any) {
        let ret: AxiosResponse;
        let chamados: any;

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })

        const e = document.getElementById('EDTEMP');

        sessionStorage.setItem("empresa", empresa.split('/')[0]);

        setTask(<Loading /> as any);


        try {
            ret = await api.get('user/find', {
                params: {
                    username: sessionStorage.getItem("username"),
                    token: token,
                    dtini: dataIni,
                    dtfim: datafim
                }
            })
            chamados = reactObj(ret.data.data);
        } catch {

        }
        //buscar historico de chamados
        if (chamados !== undefined) {
            setTasks(chamados);
            setTask(chamados);
            sessionStorage.setItem("chamados", JSON.stringify(ret.data.data));
            setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);

        } else {
            setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
        }

    }

    async function getTask() {
        let ret: AxiosResponse;
        let chamados: any;

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })

        const e = document.getElementById('EDTEMP');

        sessionStorage.setItem("empresa", empresa.split('/')[0]);

        setTask(<Loading /> as any);


        try {
            ret = await api.get('user/find', {
                params: {
                    username: sessionStorage.getItem("username"),
                    token: empresa.split('/')[2],
                    dtini: dataIni,
                    dtfim: datafim
                }
            })
            
            chamados = reactObj(ret.data.data);
        } catch {

        }
        //buscar historico de chamados
        if (chamados !== undefined) {
            setTasks(chamados);
            setTask(chamados);
            sessionStorage.setItem("chamados", JSON.stringify(ret.data.data));
            setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);

        } else {
            setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
        }

    }

    async function pesquisa() {
        let ret: AxiosResponse;
        let chamados: any;

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })
        if (empresa.length === 0 || empresa === 'now' || empresa === '-- Selecione --') {


            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Selecione a empresa desejada.`,
                confirmButtonColor: "#222d32",
            })




        } else {
            if (dataIni !== '' && datafim !== '') {

                if (moment(dataIni) > moment(new Date)) {

                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: `Data inicial não pode ser maior que data atual.`,
                        confirmButtonColor: "#222d32",
                    })


                } else {

                    if (moment(datafim).diff(moment(dataIni), 'days', true) > 30) {

                        Swal.fire({
                            icon: 'error',
                            title: 'ATENÇÃO',
                            text: `Periodo não pode ser maior que 30 dias.`,
                            confirmButtonColor: "#222d32",
                        })


                    } else {

                        const e = document.getElementById('EDTEMP');

                        sessionStorage.setItem("empresa", empresa.split('/')[0]);

                        setTask(<Loading /> as any);


                        try {
                            ret = await api.get('user/find', {
                                params: {
                                    username: sessionStorage.getItem("username"),
                                    token: empresa.split('/')[2],
                                    dtini: dataIni,
                                    dtfim: datafim
                                }
                            })
                            chamados = reactObj(ret.data.data);
                            setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined)).length);
                        } catch {

                        }

                        //buscar historico de chamados
                        if (chamados !== undefined) {
                            setTasks(chamados);
                            setTask(chamados);

                            sessionStorage.setItem("chamados", JSON.stringify(ret.data.data));
                        } else {
                            setTask(<div><br /><h2>Sem chamados recentes.</h2></div> as any)
                        }
                    }
                }
            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Selecione a data inicial e data fim para realizar a busca.`,
                    confirmButtonColor: "#222d32",
                })


            }
        }
    }


    function filtrar(id: number) {

        setFiltro(id);
        sessionStorage.setItem("filtro", id.toString())
        const newSelection = { 20: "", 30: "", 40: "", 50: "", 99: "" }
        setSelection(selection => (
            {
                ...newSelection,
                [id]: "2px dotted #000"
            }
        ))


        let tsk = JSON.parse(sessionStorage.getItem("chamados") || '');


        if (tsk !== null) {



            if (id !== 99) {

                const newTask = tsk.filter((value: any) => {
                    return value.situation.id == id ? true : false;

                })
                tsk = reactObj(newTask)
                setTask(tsk);

                setQuantNot(tsk.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);


            } else {
                tsk = reactObj(tsk)
                setTask(tsk);
            }
        }
    }

    function updateTask(rank: any, executado: any, id: any, obs: any): any {

        const tsk = JSON.parse(sessionStorage.getItem("chamados") || '');
        const newTask2: any = []

        const newTask = tsk.map((value: any) => {
            if (value.id === id) {
                newTask2.push({
                    ...value,
                    classificacao: {
                        classificacao: rank,
                        executado: executado,
                        id: id,
                        obs: obs
                    }
                })

                return <HistoryFinanceCard
                    tagColor="#4CAF50"
                    obj={{
                        ...value,
                        classificacao: {
                            classificacao: rank,
                            executado: executado,
                            id: id,
                            obs: obs
                        }
                    }}
                    updateTask={updateTask} />



            } else {
                newTask2.push({ ...value })
                return <HistoryFinanceCard
                    tagColor="#4CAF50"
                    obj={value}
                    updateTask={updateTask} />
            }
        })
        sessionStorage.setItem("chamados", JSON.stringify(newTask2));
        setTask(newTask);


        filtrar(parseInt(sessionStorage.getItem("filtro") || ''));

    }

    function notificacao() {
        if (empresa.length === 0 || empresa === 'now' || empresa === '-- Selecione --') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Selecione a empresa desejada.",
                confirmButtonColor: "#222d32",
            })

        } else {
            toggle();
            setToggleState(1);
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '35px',
            textAlign: 'left',
            borderColor: state.isFocused ? 'grey' : '#92989e',
            fontWeight: 'bold',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            padding: '0 6px',
           
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            
        }),
    };

    function filtrarLocal(id: any) {
   
        if (empresa !== '') {
            setSubtipo("")
            setOptionsLocaisFilter([])
            setLocal("")
            setOptionsSubTipoFilter([])
            setTombamento("")

            if (id !== "") {

                setTipoSolicitacao(id)

                if(empresa.split('/')[0] === 'ADLIMTI'){


                    const filterTipos = optionsSubTipo.filter(
                        (e: any) => {
                            return e.cfv_internalvalue === id.split('/')[0]
                        }
                    );

              

                    const arr2 = [] as any;

                    let result2 = filterTipos;
            
                    result2.map((data: any) => {
                        return arr2.push({ value: String(data.cev_integrationid + '/' + data.cev_description), label: data.cev_description });
                    })

                    setOptionsSubTipoFilter(arr2)

                    const arr = [] as any;

                    let result = optionsLocais;

                   
                    
                    result.map((data: any) => {
                        return arr.push({ value: data.idlocal + '/' + data.email + '/' + data.descricao, label: data.descricao });
                    })

               
                    setOptionsLocaisFilter(arr)

                } else{

                    const filterLocais = optionsLocais.filter(
                        (e: any) => {
                            return e.obs === id.split('/')[0]
                        }
                    );

                    const arr = [] as any;

                    let result = filterLocais;

                    result.map((data: any) => {
                        return arr.push({ value: data.idlocal + '/' + data.email + '/' + data.descricao, label: data.descricao });
                    })

                    setOptionsLocaisFilter(arr)
                }




            } else {
                setTipoSolicitacao(id)
            }

        } else {
            setSubtipo("")
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: "Selecione a empresa.",
                confirmButtonColor: "#222d32",
            })

        }
    }

    return (

        <DashboardBackground>

            <Header image={empresa} username={sessionStorage.getItem("username")} />

            <BodyContainer>

                <Card noShadow width="90%">

                    <h2 className="h2">ABRIR CHAMADO</h2>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-qrcode icon"></i>
                            <div className="select-box-ativos-select">
                                <Select
                                    placeholder={'DEPARTAMENTO'}
                                    options={optionsEmpresa}
                                    styles={customStyles}
                                    value={optionsEmpresa.filter(function (option: any) {
                                        return option.value === empresa;
                                    })}
                                    onChange={(options: any) =>
                                        !options ? PegarLocais('') : PegarLocais(options.value)
                                    }
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ebebeb',
                                            primary: '#52b788',

                                        },
                                    })}
                                />
                            </div>
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-user icon"></i>
                            <input className="input-field-s" type="text" id="RESPONSAVEL" placeholder="Responsável" onChange={(e) => setResponsavel(e.target.value)} value={responsavel} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-phone icon"></i>
                            <input className="input-field-s" type="text" placeholder="Telefone" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                        </LoginInput>
                    </InlineContainer>

                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-wrench icon"></i>
                            <div className="select-box-ativos-select">
                                <Select
                                    placeholder={'TIPO DE SOLICITAÇÃO'}
                                    options={optionsTipoSolicitacao}
                                    styles={customStyles}
                                    value={optionsTipoSolicitacao.filter(function (option: any) {
                                        return option.value === tipoSolicitacao;
                                    })}
                                    onChange={(options: any) =>
                                        !options ? filtrarLocal('') : filtrarLocal(options.value)
                                    }
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ebebeb',
                                            primary: '#52b788',
                                        },
                                    })}
                                />
                            </div>
                        </LoginInput>
                    </InlineContainer>

                    {empresa.split('/')[0] === 'ADLIMTI'?
                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-filter icon"></i>
                            <div className="select-box-ativos-select">
                                <Select
                                     maxMenuHeight={200}
                                    placeholder={'SUBTIPO'}
                                    options={optionsSubTipoFilter}
                                    styles={customStyles}
                                    value={optionsSubTipoFilter.filter(function (option: any) {
                                        return option.value === subtipo;
                                    })}
                                    onChange={(options: any) =>
                                        !options ? setSubtipo('') : setSubtipo(options.value)
                                    }
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ebebeb',
                                            primary: '#52b788',
                                        },
                                    })}
                                />
                            </div>
                        </LoginInput>
                    </InlineContainer>

                    : null}




                    <InlineContainer>
                        <LoginInput>
                            <i className="fa fa-qrcode icon"></i>
                            <div className="select-box-ativos-select">
                                <Select
                                    maxMenuHeight={150}
                                    placeholder={'LOCAL / EQUIPAMENTO'}
                                    options={optionsLocaisFilter}
                                    styles={customStyles}
                                    value={optionsLocaisFilter.filter(function (option: any) {
                                        return option.value === local;
                                    })}
                                    onChange={(options: any) =>
                                        !options ? setLocal('') : setLocal(options.value)
                                    }
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ebebeb',
                                            primary: '#52b788',
                                        },
                                    })}
                                />
                            </div>
                        </LoginInput>
                    </InlineContainer>

                    
                    {tipoSolicitacao.split('/')[0] === "MANUTENCAO"?
                    <InlineContainer>
                        <LoginInput>
                            <i className="fa-solid fa-file icon"></i>
                            <input className="input-field-s" type="text" placeholder="TOMBAMENTO / SEM TOMBAMENTO (DESCRIÇÃO E MODELO)" onChange={(e) => setTombamento(e.target.value)} value={tombamento} />
                        </LoginInput>
                    </InlineContainer>
                    : null}

                    <InlineContainer>
                        <LoginInput>
                            <textarea className="input-field-s" maxLength={2000} placeholder="Observação" onChange={(e) => setObservacao(e.target.value)} value={observacao} />
                        </LoginInput>
                    </InlineContainer>
                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                    <ButtonContainer>
                        <Button type="button" onClick={salvarTar} id="salvar" >ENVIAR</Button>
                    </ButtonContainer>
                </Card>
            </BodyContainer>

            <BodyContainer>
                <Card noShadow width="90%">
                    <DivTitulo>
                        <a href="#" className="notification" onClick={notificacao}>
                            <IoIosNotifications className='ico-Not' onClick={notificacao} />
                            {quantNot != '0' ?
                                <span className="badge">{quantNot}</span> : null}
                        </a>
                        <h2 className="h3">ÚLTIMOS CHAMADOS</h2>
                    </DivTitulo>

                    <Bot>

                        <button className="button button5" style={{ border: selection[20] }} onClick={() => { filtrar(20) }}>Em analise</button>
                        <button className="button button4" style={{ border: selection[30] }} onClick={() => { filtrar(30) }}>Aguardando</button>
                        <button className="button button3" style={{ border: selection[40] }} onClick={() => { filtrar(40) }}>Em campo</button>
                        <button className="button button2" style={{ border: selection[50] }} onClick={() => { filtrar(50) }}>Encerrada</button>
                        <button className="button button6" style={{ border: selection[99] }} onClick={() => { filtrar(99) }}>Todos</button>
                    </Bot>
                    <Divbut>
                        <div className="container-1">
                            <div id="content1">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idini"

                                    onChange={(e) => setDataini(e.target.value)}
                                    value={dataIni}
                                />

                            </div>
                            <div id="content2">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idfim"
                                    onChange={(e) => setDatafim(e.target.value)}
                                    value={datafim}
                                />

                            </div>
                            <div id="content3">
                                <h1 className="h1-busca" onClick={pesquisa}>Pesquisar</h1>
                            </div>

                        </div>
                    </Divbut>
                    <HistoryChamados>
                        {task}
                    </HistoryChamados>
                </Card>
            </BodyContainer>

            <Modal {...{ isShowing, toggle }}>
                <ModalHeader {...{ toggle }}>

                    <h1></h1>

                </ModalHeader>
                <ModalBody>

                </ModalBody>



                <ModalFooter>

                    <Containertab>



                        <div className="container-tab">
                            <div className="content-tabs">
                                <div
                                    className={
                                        toggleState === 1 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='container-pad'>
                                        <ModalAlinham>

                                            <FcHighPriority className="icone-conf-notif" />
                                            <h1>Atenção! Você possui {quantNot} chamados sem classificação, isso pode impactar na sua abertura de chamados.</h1>
                                            <div className="button-novoAgen">
                                                <button className="btn-agendar" onClick={() => setToggleState(2)}>
                                                    COMO REALIZAR A CLASSIFICAÇÃO
                                                </button>
                                            </div>
                                        </ModalAlinham>

                                    </div>
                                </div>
                                <div
                                    className={
                                        toggleState === 2 ? "content  active-content" : "content"
                                    }
                                >
                                    <h1>1. Verifique se o chamado foi finalizado pelo técnico representado pela cor verde.</h1>
                                    <div className='container-ajuda'>

                                        <ImArrowRight className='icon-seta' />
                                        <Container>
                                            <Tag color={'#4CAF50'} />
                                            <div>
                                                <Title>
                                                    <span>OS: 339722550 DATA: 2022-10-14 14:52:02</span>
                                                </Title>

                                                <Title>
                                                    <span>INTERNAMENTO - WC FEMININO - CHUR TORRE 1</span>
                                                </Title>
                                            </div>
                                            <div>
                                                <FaClipboardList />

                                            </div>
                                        </Container>
                                    </div>
                                    <h1>2. Selecione o ícone localizado a direita do registro para abrir o resumo da OS e realizar a classificação.</h1>
                                    <div className='container-ajuda'>


                                        <Container>
                                            <Tag color={'#4CAF50'} />
                                            <div>
                                                <Title>
                                                    <span>OS: 339722550 DATA: 2022-10-14 14:52:02</span>
                                                </Title>

                                                <Title>
                                                    <span>INTERNAMENTO - WC FEMININO - CHUR TORRE 1</span>
                                                </Title>
                                            </div>
                                            <div>
                                                <FaClipboardList />

                                            </div>
                                        </Container>
                                        <ImArrowLeft className='icon-seta-left' />
                                    </div>
                                    <h1>3. Responda as informações solicitadas e finalize a classificação conforme ilustrado.</h1>
                                    <div className='inf-ilust'>
                                        <div className='cont-ilust'>
                                            <h2 className='solicitacao'>A solicitação foi resolvida?</h2>
                                            <span> <input className="input" type="RADIO" name="OPCAO" checked disabled /> SIM
                                                <input className="input" type="RADIO" name="OPCAO" disabled /> NÃO</span>
                                            <h2 className='solicitacao'> O que você achou do nosso Atendimento?</h2>
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}
                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                            <FaStar
                                                className='estrela-cor'
                                                size={24}

                                                style={{
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />

                                        </div>
                                        <div className='cont-ilust'>
                                            <textarea
                                                placeholder="Deixar Seu Comentário" className='textarea-ilust' disabled>
                                            </textarea>


                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </Containertab>
                </ModalFooter>

            </Modal>

        </DashboardBackground>

    )
}

export default Dashboard